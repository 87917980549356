import Menu from './menu.js';
import Header from './header.js';
import Skills from './skills.js';
import Projects from './projects.js';

import './i18n';
import { useTranslation } from 'react-i18next';

function App() {
  const { t } = useTranslation();

  return <>
    <Menu />

    <Header />

    <hr />

    <h2 id="aboutMe">{ t('aboutMe.title') }</h2>

    <p className="aboutMeText">
      { t('aboutMe.description') }
    </p>

    <hr />

    <h2 id="skills">{ t('mySkills.title') }</h2>

    <Skills />

    <hr />

    <h2 id="myWork">{ t('myWork.title') }</h2>

    <Projects />
  </>;
}

export default App;
