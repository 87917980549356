import logo from './img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faGitlab } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faPatreon } from '@fortawesome/free-brands-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

import { useTranslation } from 'react-i18next';

function SocialMedia(params) {
  return <>
    <a className="socialMedia" href={ params.link } title={ params.title } id={ params.id } target={ params.target }> <FontAwesomeIcon icon={params.icon} /></a>
  </>;
}

function Header() {
  const { t } = useTranslation();

  return <>
    <div id="header">
      <img src={ logo } alt="Header" className="noSelect"></img>

      <h1 className="noSelect">Florian Cegledi</h1>

      <div>
        <SocialMedia link="https://twitter.com/commandcrafter1" title="Twitter" icon={ faTwitter } id="SocialsTwitter" target="_blank" />
        <SocialMedia link="https://github.com/CommandCrafterHD/" title="GitHub" icon={ faGithub } id="SocialsGithub" target="_blank" />
        <SocialMedia link="https://gitlab.com/CommandCrafterHD/" title="GitLab" icon={ faGitlab } id="SocialsGitlab" target="_blank" />
        <SocialMedia link="https://linkedin.com/in/florian-cegledi-141380192/" title="LinkedIn" icon={ faLinkedinIn } id="SocialsLinkedin" target="_blank" />
        <SocialMedia link="https://patreon.com/bates" title="Patreon" icon={ faPatreon } id="SocialsPatreon" target="_blank" />
        <SocialMedia link="https://discord.gg/6qd3SkP6Ch" title="Discord Server" icon={ faDiscord } id="SocialsDiscord" target="_blank" />
      </div>

      <div>
        <a href='#aboutMe' title={ t('tooltips.scroll') } id="ScrollerButton"> <FontAwesomeIcon icon={ faArrowDown } /></a>
      </div>

    </div>
  </>;
}

export default Header;
