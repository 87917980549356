// import Tippy from '@tippyjs/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHtml5 } from '@fortawesome/free-brands-svg-icons';
import { faCss3Alt } from '@fortawesome/free-brands-svg-icons';
import { faJsSquare } from '@fortawesome/free-brands-svg-icons';
import { faPhp } from '@fortawesome/free-brands-svg-icons';
import { faPython } from '@fortawesome/free-brands-svg-icons';
import { faUnity } from '@fortawesome/free-brands-svg-icons';
import { faLaravel } from '@fortawesome/free-brands-svg-icons';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import { faNodeJs } from '@fortawesome/free-brands-svg-icons';
import { faBootstrap } from '@fortawesome/free-brands-svg-icons';

const facBlender = {
  prefix: "fac",
  iconName: "Blender",
  icon: [
    45, 45,
    [],
    null,
    'M 25.783203 5.0195312 C 25.588141 5.0022187 25.390156 5.0024375 25.191406 5.0234375 C 24.394406 5.1104375 23.679734 5.5029531 23.177734 6.1269531 C 22.140734 7.4159531 22.346719 9.3087031 23.636719 10.345703 L 26.9375 13 L 9.9609375 13 C 8.3069375 13 6.9609375 14.346 6.9609375 16 C 6.9609375 17.654 8.3069375 19 9.9609375 19 L 17.396484 19 L 3.15625 30.509766 C 2.50925 31.032766 2.1055781 31.776516 2.0175781 32.603516 C 1.9305781 33.430516 2.1684062 34.243625 2.6914062 34.890625 C 3.2144063 35.537625 3.9581563 35.941297 4.7851562 36.029297 C 5.6121562 36.116297 6.4252656 35.878469 7.0722656 35.355469 L 13.998047 29.757812 C 14.577047 37.698812 21.963938 44 30.960938 44 C 40.334938 44 47.960938 37.159 47.960938 28.75 C 47.960938 23.554 45.051828 18.771359 40.173828 15.943359 L 27.396484 5.6699219 C 26.927734 5.2934219 26.368391 5.0714688 25.783203 5.0195312 z M 30.960938 20 C 35.922938 20 39.960938 23.364 39.960938 27.5 C 39.960938 31.636 35.922938 35 30.960938 35 C 25.998938 35 21.960937 31.636 21.960938 27.5 C 21.960938 23.364 25.998938 20 30.960938 20 z M 30.960938 22 A 6 5 0 0 0 30.960938 32 A 6 5 0 0 0 30.960938 22 z'
  ]
}

function Skill(params) {
  // return <>
  //   <Tippy content={ params.title } placement="bottom" arrow="true" >
  //     <p id={ params.id }> <FontAwesomeIcon icon={params.icon} /></p>
  //   </Tippy>
  // </>;
  return <>
    <p title={ params.title } id={ params.id }> <FontAwesomeIcon icon={params.icon} /></p>
  </>;
}

function Skills() {
  return <>
    <div id="skillsDiv">
      <Skill title="HTML" icon={ faHtml5 } id="languageHtml" />
      <Skill title="CSS" icon={ faCss3Alt } id="languageCss" />
      <Skill title="JS" icon={ faJsSquare } id="languageJs" />
      <Skill title="PHP" icon={ faPhp } id="languagePhp" />
      <Skill title="Python" icon={ faPython } id="languagePython" />
      <p title="C#" id="languageCs">C#</p>
      <Skill title="Laravel" icon={ faLaravel } id="languageLaravel" />
      <Skill title="React.js" icon={ faReact } id="languageReact" />
      <Skill title="Bootstrap" icon={ faBootstrap } id="languageBootstrap" />
      <Skill title="Node.js" icon={ faNodeJs } id="languageNode" />
      <Skill title="Unity Engine" icon={ faUnity } id="languageUnity" />
      <Skill title="Blender" icon={ facBlender } id="languageBlender" />
    </div>
  </>
}

export default Skills;
