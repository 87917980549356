import ReactDOM from 'react-dom';
import App from './app.js';
import './reset.css';
import './index.scss';
import SmoothScroll from 'smooth-scroll';

new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: true
});

var app = <>
  <App />
</>;

ReactDOM.render(app, document.getElementById('root'));
