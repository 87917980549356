import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faGitlab } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

import darkneon from './img/darkneon.png';
import falloutterminal from './img/falloutterminal.png';
import a10cleaningame from './img/a10cleaningame.jpg';
import ingeniousbotbanner from './img/ingeniousbotbanner.png';
import rainbowsixsiege from './img/rainbowsixsiege.jpg';
import mk6mortar from './img/mk6mortar.jpg';

import { useTranslation } from 'react-i18next';

const facBetterDiscord = {
  prefix: "fac",
  iconName: "BetterDiscord",
  icon: [
    2000, 2000,
    [],
    null,
    'M 1185.6992 135.19922 C 1212.2992 148.49922 1237.3992 163.3 1260.6992 179.5 C 1331.3992 228.6 1386.8008 291.00078 1425.3008 364.80078 C 1465.2008 441.40078 1486.7996 530.40039 1489.5996 629.40039 L 1489.5996 630.59961 L 1489.5996 631.80078 L 1489.5996 1368.9004 L 1489.5996 1370.0996 L 1489.5996 1371.3008 C 1486.8996 1470.3008 1465.3008 1559.3004 1425.3008 1635.9004 C 1386.8008 1709.7004 1331.4992 1772.0992 1260.6992 1821.1992 C 1238.0992 1836.8992 1213.7996 1851.3008 1188.0996 1864.3008 L 1260.5996 1864.3008 C 1606.7996 1866.2008 1931.5996 1693.1004 1931.5996 1296.4004 L 1931.5996 716.69922 C 1933.4996 312.19922 1608.7 135.19922 1262.5 135.19922 L 1185.6992 135.19922 z M 68.400391 135.69922 L 68.400391 849.80078 L 510.40039 1247.8008 L 510.40039 490.69922 L 767.40039 490.69922 C 1041.9004 490.69922 1041.9004 835.59961 767.40039 835.59961 L 597.59961 835.59961 L 597.59961 1165.0996 L 767.40039 1165.0996 C 1041.9004 1165.0996 1041.9004 1509.9004 767.40039 1509.9004 L 68.400391 1509.9004 L 68.400391 1864.8008 L 759.59961 1864.8008 C 1115.8996 1864.8008 1392.3992 1722.2008 1402.1992 1368.8008 C 1402.1992 1206.2008 1357.7008 1084.6992 1279.3008 1000.1992 C 1357.7008 915.79922 1402.1992 794.29922 1402.1992 631.69922 C 1392.4992 278.29922 1115.9996 135.69922 759.59961 135.69922 L 68.400391 135.69922 z'
  ]
}

function CardImage(props) {
  return <>
    <a href={ props.img } target="_blank" rel="noreferrer" className="noSelect"><img src={ props.img } alt={ props.imgName } /></a>
   </>;
}

function CardButton(props) {
  return <>
    <a href={ props.link } target={ props.target } className={ props.classes + " noSelect" }>
      <FontAwesomeIcon icon={ props.icon } />
      { props.text }
    </a>
  </>
}

function Projects() {
  const { t } = useTranslation();

  return <>
    <div id="projectsDiv">
      <div className="projectCard">
        <CardImage img={ darkneon } imgName="darkneon.png" />
        <h3>{ t('myWork.darkNeonTitle') }</h3>
        <p>{ t('myWork.darkNeonDescription') }</p>
        <CardButton link="https://betterdiscord.app/theme/Dark%20Neon" target="_blank" icon={ facBetterDiscord } text={ t('myWork.downloadButton') } classes="cardButton cardButtonBetterDiscord" />
        <CardButton link="https://github.com/CommandCrafterHD/DiscordDarkNeon" target="_blank" icon={ faGithub } text={ t('myWork.sourceCodeButton') } classes="cardButton cardButtonGithub" />
        <CardButton link="https://gitlab.com/CommandCrafterHD/bates-discord-theme" target="_blank" icon={ faGitlab } text={ t('myWork.sourceCodeButton') } classes="cardButton cardButtonGitlab" />
      </div>

      <div className="projectCard">
        <CardImage img={ falloutterminal } imgName="falloutterminal.png" />
        <h3>{ t('myWork.falloutTitle') }</h3>
        <p>{ t('myWork.falloutDescription') }</p>
        <CardButton link="https://betterdiscord.app/theme/Fallout%204%20Terminal" target="_blank" icon={ facBetterDiscord } text={ t('myWork.downloadButton') } classes="cardButton cardButtonBetterDiscord" />
        <CardButton link="https://github.com/CommandCrafterHD/Fallout4TerminalTheme" target="_blank" icon={ faGithub } text={ t('myWork.sourceCodeButton') } classes="cardButton cardButtonGithub" />
      </div>

      <div className="projectCard">
        <CardImage img={ a10cleaningame } imgName="a10cleaningame.jpg" />
        <h3>{ t('myWork.a10Title') }</h3>
        <p>{ t('myWork.a10Description') }</p>
        <CardButton link="https://www.digitalcombatsimulator.com/de/files/3312693/" target="_blank" icon={ faGlobe } text={ t('myWork.downloadButton') } classes="cardButton cardButtonDCS" />
      </div>

      <div className="projectCard">
        <CardImage img={ ingeniousbotbanner } imgName="ingeniousbotbanner.png" />
        <h3>{ t('myWork.ingeniousTitle') }</h3>
        <p>{ t('myWork.ingeniousDescription') }</p>
        <CardButton link="https://gitlab.com/CommandCrafterHD/ingenious-bot-rewrite" target="_blank" icon={ faGitlab } text={ t('myWork.sourceCodeButton') } classes="cardButton cardButtonGitlab" />
      </div>

      <div className="projectCard">
        <CardImage img={ rainbowsixsiege } imgName="rainbowsixsiege.jpg" />
        <h3>{ t('myWork.r6Title') }</h3>
        <p>{ t('myWork.r6Description') }</p>
        <CardButton link="https://gitlab.com/CommandCrafterHD/rainbow-six-siege-enemy-lookup" target="_blank" icon={ faGitlab } text={ t('myWork.sourceCodeButton') } classes="cardButton cardButtonGitlab" />
      </div>

      <div className="projectCard">
        <CardImage img={ mk6mortar } imgName="mk6mortar.jpg" />
        <h3>{ t('myWork.armaTitle') }</h3>
        <p>{ t('myWork.armaDescription') }</p>
        <CardButton link="https://gitlab.com/CommandCrafterHD/arma3-82mm-calculator" target="_blank" icon={ faGitlab } text={ t('myWork.sourceCodeButton') } classes="cardButton cardButtonGitlab" />
      </div>
    </div>
  </>;
}

export default Projects;
