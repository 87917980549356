import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          tooltips: {
            scroll: "Scroll down",
            language: "Switch to german",
            close: "Close"
          },
          aboutMe: {
            title: "About me",
            description: "Hey! Welcome to my humble website. My name is Florian Cegledi and I'm a german computer nerd. I like to try out new ways to create software and content whenever I get the chance, which is why my skills in any particular topic might be limited, but my skillset is broad!"
          },
          mySkills: {
            title: "My skills"
          },
          myWork: {
            title: "My work",
            darkNeonTitle: "Dark Neon theme",
            darkNeonDescription: "The Dark Neon theme for BetterDiscord is the first ever theme I made. I basically just made it to have an even darker alternative to discord's default dark mode.",
            falloutTitle: "Fallout 4 Terminal theme",
            falloutDescription: "The Fallout 4 Terminal theme for BetterDiscord is the second theme I have ever made. It's also the first time I put a theme just on Github instead of having the GitHub repo mirror the GitLab one.",
            a10Title: "A10CII Black & Red Livery",
            a10Description: "I created this livery a while ago, just to be able to have a sleeker appearance while doing gun-runs on enemy positions in DCS.",
            ingeniousTitle: "Ingenious Bot",
            ingeniousDescription: "The Ingenious Bot for discord was a project that went through a couple iterations (in total probably 3 complete rewrites). It's goal was to provide as many useful or just funny features as possible, although the last running instance was shut down at some point around 2019.",
            r6Title: "R6: Enemy Lookup",
            r6Description: "The Rainbow Six Siege: Enemy lookup is a simple script I created which takes a screenshot of the ingame scoreboard and then tries to figure out the enemie's names using image recognition to run them against a public database. Sadly the database got shut down at some point and recent UI changes made the text-recognition even harder, basically making this script useless.",
            armaTitle: "82mm Mortar Calculator",
            armaDescription: "The 82mm Mortar Calculator for Arma 3 is one of my proudest scripts, it takes simple information like positions/directions and some wind information and returns the needed angle and elevation to hit shots accurate to 20m. It does require the ACE mod for its ballistic-calculations, as it won't work with the default MK6 mortar.",

            sourceCodeButton: "Source Code",
            downloadButton: "Download"
          },
          legal: {
            imprintTitle: "Imprint",
            imprintText: "Florian Cegledi\nWeidenfeldstraße 19\n35216 Biedenkopf\nwebadmin@cegledi.net",
            privacyTitle: "Privacy (only in german for now, sorry)",
            privacyText: ""
          }
        }
      },
      de: {
        translation: {
          tooltips: {
            scroll: "Runter scrollen",
            language: "Auf Englisch wechseln"
          },
          aboutMe: {
            title: "Über mich",
            description: "Hey! Willkommen auf meiner bescheidenen Website. Mein Name ist Florian Cegledi und ich bin ein deutscher Computer Nerd. Ich versuche mich gerne an neuen Methoden Software und digitale Inhalte zu kreieren, weshalb meine Fähigkeiten zwar limitiert aber dafür breit gefächert sind!"
          },
          mySkills: {
            title: "Meine Fähigkeiten"
          },
          myWork: {
            title: "Meine Projekte",
            darkNeonTitle: "Dark Neon Theme",
            darkNeonDescription: "Das Dark Neon Theme für BetterDiscord ist das erste Theme was ich je erstellt habe. Ich habe es prinzipiell als dunklere Alternative zu Discord's eigenem Dark-Mode erstellt.",
            falloutTitle: "Fallout 4 Terminal Theme",
            falloutDescription: "Das Fallout 4 Terminal Theme für BetterDiscord ist das zweite Theme das ich erstellt habe. Es ist außerdem das erste Theme welches nur auf GitHub gespeichert ist, anstatt einfach ein GitLab Repo auf GitHub zu klonen.",
            a10Title: "A10CII Black & Red Lackierung",
            a10Description: "Diese Lackierung habe ich vor einer Weile erstellt um bei Gunruns in DCS einfach cooler auszusehen.",
            ingeniousTitle: "Ingenious Bot",
            ingeniousDescription: "Der Ingenious Bot für Discord ist ein Projekt welches durch ein paar Iterationen gegangen ist (insgesamt ca. 3 komplette neuanfänge). Sein Ziel war es möglichst viele hilfreiche oder spaßige Funktionen in einem Bot zu vereinen, die Letzte laufende Instanz des Bots wurde aber ca. 2019 abgeschaltet.",
            r6Title: "R6: Gegner Suche",
            r6Description: "Die Rainbow Six Siege: Gegner Suche ist ein simples Script, welches einen Screenshot vom Scoreboard im Spiel macht und dann versucht per Texterkennung die Namen der Gegner zu lesen und über eine öffentliche Datenbank daten wie Ränge zu erfragen. Leider wurde die Datenbank irgendwann abgeschafft und UI änderungen im Spiel haben das lesen von Namen stark erschwert was dieses Script prinzipiell nutzlos macht.",
            armaTitle: "82mm Mörser Rechner",
            armaDescription: "Der 82mm Mörser Rechner für Arma 3 ist eines der Scripte auf die ich am stolzesten bin, es nimmt simple Informationen wie Position und Richtung und errechnet damit die Höhe/Richtung die man benötigt um Mörser Schüsse auf 20m genau zu treffen. Es braucht jedoch die ACE Mod für ihre Ballistische-Berechnung, da der Standart MK6 Mörser den Luftwiederstand ignoriert.",

            sourceCodeButton: "Quellcode",
            downloadButton: "Herunterladen"
          },
          legal: {
            imprintTitle: "Impressum",
            imprintText: "Florian Cegledi\nWeidenfeldstraße 19\n35216 Biedenkopf\nwebadmin@cegledi.net",
            privacyTitle: "Datenschutz",
            privacyText: ""
          }
        }
      }
    }
  });

export default i18n;
